import React from 'react';

const CustomerCard = ({ customer }) => {
    return (
        <div className="bg-white rounded-lg shadow-md p-6 hover:shadow-lg transition-shadow">
            <div className="flex justify-between items-start mb-4">
                <div>
                    <h2 className="text-xl font-semibold text-gray-800">
                        {customer.firstName} {customer.lastName}
                    </h2>
                    <p className="text-gray-600">{customer.email}</p>
                </div>
                <div className="flex items-center">
                    <span className={`h-3 w-3 rounded-full ${customer.isActive ? 'bg-green-500' : 'bg-gray-300'}`}></span>
                </div>
            </div>
            
            <div className="space-y-2">
                <p className="text-sm text-gray-600">
                    <span className="font-medium">Phone:</span> {customer.phone}
                </p>
                <div className="text-sm text-gray-600">
                    <span className="font-medium">Address:</span><br />
                    {customer.address.address1}
                    {customer.address.address2 && <>, {customer.address.address2}</>}<br />
                    {customer.address.city}, {customer.address.state} {customer.address.zip}<br />
                    {customer.address.country}
                </div>
            </div>
            
            <div className="mt-4 pt-4 border-t border-gray-200">
                <div className="flex justify-between text-sm">
                    <span className="text-gray-600">Stories: {customer.stories.length}</span>
                    <span className="text-gray-600">Albums: {customer.albums.length}</span>
                </div>
            </div>
        </div>
    );
};

export default CustomerCard;
