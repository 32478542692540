import React, { useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2'
import InputMask from "react-input-mask"; 

const AddUserAndCharge = () => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    address: {
      address1: "",
      address2: "", // Optional
      city: "",
      state: "",
      zip: "",
      country: "",
    },
  });


  const [emailError, setEmailError] = useState('');
  const [firstNameError, setFirstNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [address1Error, setAddress1Error] = useState('');
  const [cityError, setCityError] = useState('');
  const [stateError, setStateError] = useState('');
  const [zipError, setZipError] = useState('');
  const [countryError, setCountryError] = useState('');
  const [phoneError, setPhoneError] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name.startsWith('address.')) {
      const addressField = name.split('.')[1];
      setFormData(prev => ({
        ...prev,
        address: {
          ...prev.address,
          [addressField]: value
        }
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: value
      }));
    }
  };

  const validateInput = () => {
    let isValid = true;

    const setError = (setter, condition, message) => {
      if (condition) {
        setter(message);
        isValid = false;
      } else {
        setter('');
      }
    };

    setError(setEmailError, !formData.email.includes('@'), 'Please enter a valid email address.');
    setError(setFirstNameError, formData.firstName.trim() === '', 'Required field.');
    setError(setLastNameError, formData.lastName.trim() === '', 'Required field.');
    setError(setAddress1Error, formData.address.address1.trim() === '', 'Required field.');
    setError(setCityError, formData.address.city.trim() === '', 'Required field.');
    setError(setStateError, formData.address.state.trim() === '', 'Required field.');
    setError(setZipError, formData.address.zip.trim() === '', 'Required field.');
    setError(setCountryError, formData.address.country.trim() === '', 'Required field.');
    setError(setPhoneError, formData.phone.replace(/\D/g, '').length < 10, 'Please enter a valid phone number with at least 10 digits.');

    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setEmailError('');
    setCityError('');
    setCountryError('');
    setFirstNameError('');
    setLastNameError('');
    setZipError('');
    setStateError('');
    setAddress1Error('');
    setPhoneError('');

    if (validateInput()) {
      const trimmedData = {
        ...formData,
        firstName: formData.firstName.trim(),
        lastName: formData.lastName.trim(),
        email: formData.email.trim(),
        phone: formData.phone.trim(),
        address: {
          ...formData.address,
          address1: formData.address.address1.trim(),
          address2: formData.address.address2?.trim() || '',
          city: formData.address.city.trim(),
          state: formData.address.state.trim(),
          zip: formData.address.zip.trim(),
          country: formData.address.country.trim()
        }
      };

      try {
        const response = await fetch(
          "https://kinalma-app-696ccda80bc9.herokuapp.com/createCustomer",
          {
              method: "POST",
              headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + currentUser.token,
              },
              body: JSON.stringify(trimmedData),
            }
        );
        const data = await response.json();
        if (response.ok) {
           Swal.fire({
              title: 'Success!',
              text: 'User added and charged successfully!',
              icon: 'success',
              showConfirmButton: false,
              timer: 2500
            })
          navigate("/dashboard");
        } else {
          Swal.fire({ 
            title: 'Error!',
            text: data.message || "Something went wrong!",
            icon: 'error',
            showConfirmButton: false,
            timer: 2500
          })
        }
      } catch (error) {
        Swal.fire({ 
          title: 'Error!',
          text: "Failed to add user and charge.",
          icon: 'error',
          showConfirmButton: false,
          timer: 2500
        })
      } finally {
        setLoading(false);
      }
      return;
    } else {
      setLoading(false);
      return;
    }
  };

  const states = [
    "AL", "AK", "AZ", "AR", "CA", "CO", "CT", "DE", "FL", "GA", "HI", "ID", 
    "IL", "IN", "IA", "KS", "KY", "LA", "ME", "MD", "MA", "MI", "MN", "MS", 
    "MO", "MT", "NE", "NV", "NH", "NJ", "NM", "NY", "NC", "ND", "OH", "OK", 
    "OR", "PA", "RI", "SC", "SD", "TN", "TX", "UT", "VT", "VA", "WA", "WV", 
    "WI", "WY"
  ];

  return (
    
    <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
          <nav className="fixed top-0 left-0 right-0 z-50 bg-white/80 backdrop-blur-sm w-full">
      <div className="container mx-auto flex items-center py-4">
        <div className="w-[150px]">
          <a href="/" className="text-primary text-pink-400 font-bold text-4xl">
            KinAlma
          </a>
        </div>
        <div className="flex-1 flex justify-center">
          <div className="hidden items-center gap-8 md:flex">
           
          </div>
        </div>
        <div className="w-[150px] flex justify-end">
          <button
            className="font-medium bg-transparent text-pink-400 cursor-pointer text-lg"
            onClick={() => navigate("/dashboard")}
          >
            {"Go Back"}
          </button>
        </div>
      </div>
    </nav>

      <form onSubmit={handleSubmit} className="mt-12">
        <div className="space-y-12">
          <div className="border-b border-gray-900/10 pb-8">
            <h2 className="text-4xl font-semibold text-gray-700">
              Add New KinAlma Customer
            </h2>
            {/* <p className="mt-1 text-sm/6 text-gray-600">
              Use a permanent address where you can receive mail.
            </p> */}

            <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-5 sm:grid-cols-6">
              {/* First Name */}
              <div className="sm:col-span-3">
                <label
                  htmlFor="firstName"
                  className="block text-sm/6 font-medium text-gray-900"
                >
                  First name
                </label>
                <div className="mt-1">
                  <input
                    id="firstName"
                    name="firstName"
                    autoComplete="given-name"
                    value={formData.firstName}
                    onChange={handleChange}
                    className={`block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 ${
                      firstNameError ? 'outline-red-600' : 'outline-gray-300'
                    } placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6`}
                  />
                  {firstNameError && (
                    <p className="mt-1 text-sm text-red-600">{firstNameError}</p>
                  )}
                </div>
              </div>

              {/* Last Name */}
              <div className="sm:col-span-3">
                <label
                  htmlFor="lastName"
                  className="block text-sm/6 font-medium text-gray-900"
                >
                  Last name
                </label>
                <div className="mt-1">
                  <input
                    id="lastName"
                    name="lastName"
                    autoComplete="family-name"
                    value={formData.lastName}
                    onChange={handleChange}
                    className={`block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 ${
                      lastNameError ? 'outline-red-600' : 'outline-gray-300'
                    } placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6`}
                  />
                  {lastNameError && (
                    <p className="mt-1 text-sm text-red-600">{lastNameError}</p>
                  )}
                </div>
              </div>

              {/* Email */}
              <div className="sm:col-span-3">
                <label
                  htmlFor="email"
                  className="block text-sm/6 font-medium text-gray-900"
                >
                  Customer Email address
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    name="email"
                    autoComplete="email"
                    value={formData.email}
                    onChange={handleChange}
                    className={`block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 ${
                      emailError ? 'outline-red-600' : 'outline-gray-300'
                    } placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6`}
                  />
                  {emailError && (
                    <p className="mt-1 text-sm text-red-600">{emailError}</p>
                  )}
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="phone"
                  className="block text-sm/6 font-medium text-gray-900"
                >
                  Customer Phone Number
                </label>
                <div className="mt-1">
                <InputMask
                      mask="(999) 999-9999" // Define the mask pattern here
                      maskChar=" " // Optional: character to display for unfilled parts
                      id="phone"
                      name="phone"
                      autoComplete="tel"
                      value={formData.phone}
                      onChange={handleChange}
                    >
                      {(inputProps) => (
                        <input
                          {...inputProps}
                          className={`block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 ${
                            phoneError ? 'outline-red-600' : 'outline-gray-300'
                          } placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6`}
                        />
                      )}
                    </InputMask>
                    {phoneError && (
                    <p className="mt-1 text-sm text-red-600">{phoneError}</p>
                  )}
                </div>
              </div>

              {/* Address Fields */}
              <div className="col-span-full">
                <label
                  htmlFor="address1"
                  className="block text-sm/6 font-medium text-gray-900"
                >
                  Street Address 1
                </label>
                <div className="mt-1">
                  <input
                    id="address1"
                    name="address.address1"
                    autoComplete="street-address"
                    value={formData.address.address1}
                    onChange={handleChange}
                    className={`block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 ${
                      address1Error ? 'outline-red-600' : 'outline-gray-300'
                    } placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6`}
                  />
                  {address1Error && (
                    <p className="mt-1 text-sm text-red-600">{address1Error}</p>
                  )}
                </div>
              </div>

              <div className="col-span-full">
                <label
                  htmlFor="address2"
                  className="block text-sm/6 font-medium text-gray-900"
                >
                  Apartment, suite, etc. (optional)
                </label>
                <div className="mt-1">
                  <input
                    id="address2"
                    name="address.address2"
                    autoComplete="address-line2"
                    value={formData.address.address2}
                    onChange={handleChange}
                    className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6"
                  />
                </div>
              </div>

              {/* Country */}
              <div className="sm:col-span-2">
                  <label htmlFor="country" className="block text-sm/6 font-medium text-gray-900">
                    Country
                  </label>
                  <div className="mt-1 grid grid-cols-1">
                    <select
                      id="country"
                      name="address.country"
                      autoComplete="country-name"
                      value={formData.address.country}
                      onChange={handleChange}
                      className={`block w-full rounded-md bg-white py-2 pl-3 pr-8 text-base text-gray-900 outline outline-1 -outline-offset-1 ${
                        countryError ? 'outline-red-600' : 'outline-gray-300'
                      } placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm`}
                    >
                      <option value="">Select Country</option>
                      <option value="United States">United States</option>
                      <option value="Canada">Canada</option>
                      <option value="Mexico">Mexico</option>
                    </select>
                    {countryError && (
                    <p className="mt-1 text-sm text-red-600">{countryError}</p>
                  )}
                  </div>
                </div>

              <div className="sm:col-span-2">
                <label
                  htmlFor="city"
                  className="block text-sm/6 font-medium text-gray-900"
                >
                  City
                </label>
                <div className="mt-1">
                  <input
                    id="city"
                    name="address.city"
                    autoComplete="address-level2"
                    value={formData.address.city}
                    onChange={handleChange}
                    className={`block w-full rounded-md bg-white py-2 pl-3 pr-8 text-base text-gray-900 outline outline-1 -outline-offset-1 ${
                      cityError ? 'outline-red-600' : 'outline-gray-300'
                    } placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm`}
                  />
                  {cityError && (
                    <p className="mt-1 text-sm text-red-600">{cityError}</p>
                  )}
                </div>
              </div>

          
               {/* State */}
               <div className="sm:col-span-3">
                  <label htmlFor="state" className="block text-sm font-medium text-gray-900">
                    State
                  </label>
                  <div className="mt-1 grid grid-cols-1">
                    <select
                      id="state"
                      name="address.state"
                      autoComplete="address-level1"
                      value={formData.address.state}
                      onChange={handleChange}
                      className={`block w-full rounded-md bg-white py-2 pl-3 pr-8 text-base text-gray-900 outline outline-1 -outline-offset-1 ${
                        stateError ? 'outline-red-600' : 'outline-gray-300'
                      } placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm`}
                    >
                      <option value="">Select State</option>
                      {states.map((state) => (
                        <option key={state} value={state}>{state}</option>
                      ))}
                    </select>
                    {stateError && (
                    <p className="mt-1 text-sm text-red-600">{stateError}</p>
                  )}
                  </div>
                </div>

                {/* Zip */}
                <div className="sm:col-span-3">
                  <label htmlFor="zip" className="block text-sm font-medium text-gray-900">
                    Zip
                  </label>
                  <div className="mt-1">
                    <input
                      id="zip"
                      name="address.zip"
                      autoComplete="postal-code"
                      value={formData.address.zip}
                      onChange={handleChange}
                      className={`block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 ${
                        zipError ? 'outline-red-600' : 'outline-gray-300'
                      } placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6`}
                    />
                    {zipError && (
                    <p className="mt-1 text-sm text-red-600">{zipError}</p>
                  )}
                  </div>
                </div>


            </div>
          </div>
        </div>
        {/* Submit Button */}
        <div className="mt-6 flex items-center justify-end gap-x-6">
          <button
            type="button"
            className="text-sm/6 font-semibold text-gray-900"
            onClick={() => navigate("/dashboard")}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            {loading ? "Submitting..." : "Create New Customer"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddUserAndCharge;