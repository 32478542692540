import React, { createContext, useContext, useState, useEffect } from 'react';
import { jwtDecode } from "jwt-decode";
import Swal from 'sweetalert2'

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);

  const checkCompanyEmail = async (email) => {
    try {
      const response = await fetch('https://kinalma-app-696ccda80bc9.herokuapp.com/checkCompanyEmail', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });
      const data = await response.json();
      console.log(data);
      if (response.ok) {
        Swal.fire({
          title: 'Code Sent!',
          text: 'Check your email!',
          icon: 'success',
          showConfirmButton: false,
          timer: 2500
        })
        return true;
      } else {
        Swal.fire({
          title: 'Error!',
          text: data['message'],
          icon: 'error',
          showConfirmButton: false,
          timer: 2500
        })
        return false;
      }
    } catch (error) {
      console.log(error)
      console.error('Email check failed:', error);
      return false;
    }
  };

  const companyLoginWithCode = async (email, code) => {
    try {
      const response = await fetch('https://kinalma-app-696ccda80bc9.herokuapp.com/companyLoginWithCode', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: email, loginCode: code }),
      });
      const data = await response.json();
      console.log(data)

      if (response.ok) {
        // Swal.fire({
        //   title: 'Success!',
        //   text: 'Code verified!',
        //   icon: 'success',
        //   showConfirmButton: false,
        //   timer: 500
        // })
        const token = data['token'];
        const companyInfo = data;
        console.log("Company Info: ",companyInfo)
        localStorage.setItem('token', token);
        setCurrentUser({ token, companyInfo });
        console.log("Current user from Context: ", currentUser);
        return true;
      } else {
        Swal.fire({
          title: 'Error!',
          text: data['message'],
          icon: 'error',
          showConfirmButton: false,
          timer: 2500
        })
        return false;
      }
    } catch (error) {
      console.error('Code verification failed:', error);
      return false;
    }
  };

  const checkTokenExpiry = (token) => {
    const decoded = jwtDecode(token);
    const currentTime = Date.now() / 1000;
    return decoded.exp > currentTime;
  };

  const signUp = async (companyName, address, email, phone) => {
    try {
      const response = await fetch('https://kinalma-app-696ccda80bc9.herokuapp.com/companySignup', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ companyName: companyName, address: address, email: email, phone: phone }),
      });
      const data = await response.json();
      console.log(data);
      console.log("Response:  ", response.ok);

      if (response.ok) {
        return true;
      }
      return false

    } catch (error) {
      console.log('Sign up failed: ', error);
      return false
    }

  }

  const logout = () => {
    localStorage.removeItem('token');
    setCurrentUser(null);
  };
  const getUser = async () => {
    try {
      const response = await fetch('https://kinalma-app-696ccda80bc9.herokuapp.com/getCompanyInfo', {
        method: 'POST',
        headers: {
          'Authorization': 'Bearer ' + currentUser['token'],
        }
      });
  
      if (!response.ok) {
        // If the response status is not OK, handle it
        console.log(`Error: ${response.status} - ${response.statusText}`);
        return null;
      }
  
      // Parse the response body as JSON
      const data = await response.json();
      return data;
  
    } catch (error) {
      console.error('Get Company failed: ', error);
      return null;
    }
  };
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token && checkTokenExpiry(token)) {
      setCurrentUser({ token });
    } else {
      console.log("Token is expired or invalid");
      logout();
    }
  }, []);

  const value = { currentUser, checkCompanyEmail, companyLoginWithCode, signUp, logout, getUser };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);
