import React from "react";
import image1 from "../assets/image1.jpg";
import image2 from "../assets/image2.jpg";
import image3 from "../assets/image3.jpg";
import image4 from "../assets/image4.jpg";
import image5 from "../assets/image5.jpg";

export function ImageGrid() {
  const vintageImageStyle = "object-cover filter brightness-95 contrast-90";
  
  return (
    <div className="relative w-[700px] h-[600px]">
      {/* Top Left Image */}
      <div className="absolute top-0 left-[30%] rounded-2xl overflow-hidden shadow-lg transform translate-y-[-10%] translate-x-[-10%]">
        <img
          src={image5}
          alt="People collaborating"
          className={`${vintageImageStyle} w-[200px] h-[300px]`}
        />
      </div>

      {/* Top Right Image */}
      <div className="absolute top-10 right-0 rounded-2xl overflow-hidden shadow-lg transform translate-x-[10%]">
        <img
          src={image2}
          alt="Person working"
          className={`${vintageImageStyle} w-[250px] h-[300px]`}
        />
      </div>

      {/* Center Image */}
      <div className="absolute top-[40%] left-[30%] rounded-2xl overflow-hidden shadow-lg transform translate-y-[-10%]">
        <img
          src={image3}
          alt="Team meeting"
          className={`${vintageImageStyle} w-[300px] h-[350px]`}
        />
      </div>

      {/* Bottom Left Image */}
      <div className="absolute -bottom-10 left-[20%] rounded-2xl overflow-hidden shadow-lg transform translate-x-[-5%]">
        <img
          src={image1}
          alt="Office collaboration"
          className={`${vintageImageStyle} w-[200px] h-[250px]`}
        />
      </div>

      {/* Bottom Right Image */}
      <div className="absolute bottom-0 right-0 rounded-2xl overflow-hidden shadow-lg transform translate-x-[10%]">
        <img
          src={image4}
          alt="Remote work"
          className={`${vintageImageStyle} w-[275px] h-[300px]`}
        />
      </div>
    </div>
  );
}
