import React, { useEffect, useState } from 'react';
import { DashboardNav } from './DashboarNav';
import { useAuth } from '../../contexts/AuthContext';
import CustomerCard from './CustomerCard';

const Customers = () => {
    const [customers, setCustomers] = useState([]);
    const { getUser } = useAuth();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchUserData = async () => {
            const data = await getUser(); 
            if (data && data.companyInfo && data.companyInfo.companyClients) {
                setCustomers(data.companyInfo.companyClients);
            } else {
                setCustomers([]);
            }
            setLoading(false);
        };
        fetchUserData();
    }, []);

    return (
        <div className="flex flex-col min-h-screen bg-gray-100">
            <DashboardNav />
            
            <div className="container mx-auto px-4 py-8">
                <h1 className="text-3xl font-bold text-gray-800 mb-6">Your Customers</h1>
                
                {loading ? (
                    <div className="flex justify-center items-center">
                        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-pink-400"></div>
                    </div>
                ) : customers.length === 0 ? (
                    <div className="text-center py-8">
                        <p className="text-gray-600">No customers found.</p>
                    </div>
                ) : (
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                        {customers.map((customer) => (
                            <CustomerCard key={customer._id} customer={customer} />
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default Customers;
